<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        {{ $t("routecode") }} : {{ routeData.name }}
      </h3>
    </div>
    <footer class="m-6">
      <div class="card-toolbar row justify-content-center">
        <div class="col-sm-11 mb-0">
          <button
            type="button"
            class="btn btn-sm btn-light btn-primary ms-3 me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submit()"
          >
            {{ $t("save") }}
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
    </footer>
    <!-- คั่น -->
    <div class="card-body">
      <div class="card-toolbar row justify-content-center">
        <div
          class="col-sm-5"
          style="
            border: solid lightgray 0.5px;
            padding: 20px;
            border-radius: 10px;
          "
        >
          <b
            ><u>{{ $t("ลูกค้าทั้งหมด") }}</u></b
          >
          <div>
            <input
              v-model="filterleftArray"
              type="text"
              class="form-control"
              placeholder="ตัวกรอง"
            />
          </div>
          <div>
            <el-checkbox
              type="checkbox"
              v-model="allLeftCheckbox"
              @change="checkAllitemsleftArray()"
            />
            <span class="form-check-label"> เลือกทั้งหมด </span>
          </div>
          <div
            v-for="customer in newLeftArray"
            :key="customer"
            class="col-sm-12 me-1"
          >
            <label
              class="
                form-check form-check-sm form-check-custom form-check-solid
                me-5
                mb-2
                mt-2
              "
            >
              <el-checkbox type="checkbox" v-model="customer.isSelected" />
              <span class="form-check-label">
                {{ customer.code }} {{ customer.name }}
              </span>
            </label>
          </div>
        </div>
        <div class="col-sm-1 mt-6" style="text-align: center">
          <button
            type="button"
            class="btn btn-light btn-primary"
            style="font-size: 14px; font-weight: bold"
            @click="appendRightArray()"
          >
            <i class="fas fa-arrow-right"></i>
          </button>
          <button
            type="button"
            class="btn btn-light btn-danger"
            style="font-size: 14px; font-weight: bold; margin-top: 10px"
            @click="appendLeftArray()"
          >
            <i class="fas fa-arrow-left"></i>
          </button>
        </div>
        <div
          class="col-sm-5 ms-1"
          style="
            border: solid lightgray 0.5px;
            padding: 20px;
            border-radius: 10px;
          "
        >
          <b
            ><u>{{ $t("ลูกค้าที่เลือก") }}</u></b
          >
          <div>
            <input
              v-model="filterrightArray"
              type="text"
              class="form-control"
              placeholder="ตัวกรอง"
            />
          </div>
          <div>
            <el-checkbox
              type="checkbox"
              v-model="allRightCheckbox"
              @change="checkAllitemsrightArray()"
            />
            <span class="form-check-label"> เลือกทั้งหมด </span>
          </div>
          <div v-for="item in newRightArray" :key="item" class="col-sm-12 me-1">
            <label
              class="
                form-check form-check-sm form-check-custom form-check-solid
                me-5
                mb-2
                mt-2
              "
            >
              <el-checkbox type="checkbox" v-model="item.isSelected" />
              <span class="form-check-label">
                {{ item.code }} {{ item.name }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import customerAPI from "@/api/customer/";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";

export default {
  setup() {
    document.title = "J&N | เพิ่มข้อมูลลูกค้า";
  },
  components: {
    DialogConfirmByPass,
    Pagination,
    Search,
    Loader,
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    checkAll: false,
    dialogConfirmByPass: false,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    allData: [],
    routeData: [],
    itemsleftArray: [],
    itemsrightArray: [],
    allLeftCheckbox: false,
    allRightCheckbox: false,
    filterleftArray: "",
    filterrightArray: "",
    newLeftArray: [],
    newRightArray: [],
  }),

  async created() {
    await this.getAllCustomer();
    this.getCustomerByRouteId();
    await this.getRoute();
  },
  watch: {
    filterleftArray(val) {
      let result = this.itemsleftArray.filter(
        (element) =>
          // element.van_name == val
          element.name.includes(val) || element.code.includes(val)
      );
      console.log("result", result);
      return (this.newLeftArray = result);
    },
    filterrightArray(val) {
      let result = this.itemsrightArray.filter(
        (element) =>
          // element.van_name == val
          element.name.includes(val) || element.code.includes(val)
      );
      console.log("result", result);
      return (this.newRightArray = result);
    },
  },
  methods: {
    async getCustomerByRouteId(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await customerAPI.customer.getCustomerByRouteId(
          this.$route.query.id
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        // this.dataItemsShow = responseData.data;
        // this.tableItems = responseData.data;
        // this.allData = responseData.data;
        this.itemsrightArray = responseData.data;
        this.newRightArray = this.itemsrightArray;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async submit() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let updateResponse = [];

          this.form = {
            ...this.routeData,
            routes: this.itemsrightArray,
          };
          console.log("this.form", this.form);

          this.loadingUpdate = true;
          updateResponse = await customerAPI.route.update(
            this.$route.query.id,
            this.form
          );
          if (updateResponse.response_status === "SUCCESS") {
            Swal.fire({
              icon: "success",
              title: `แก้ไขสำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.$router.push({
                path: "/CUSTOMER/route/index",
                query: {},
              });
            });
            this.loadingUpdate = false;
          } else {
            Swal.fire({
              icon: "error",
              title: `${updateResponse.message}`,
              showConfirmButton: false,
              timer: 1500,
            });
            this.loadingUpdate = false;
          }
        }
      });
    },
    cancel() {
      Swal.fire({
        icon: "success",
        title: `บันทึกข้อมูลสำเร็จ`,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        window.close();
      });
    },
    checkAllitemsleftArray() {
      if (this.allLeftCheckbox) {
        this.itemsleftArray.forEach((element) => {
          element.isSelected = true;
        });
      } else {
        this.itemsleftArray.forEach((element) => {
          element.isSelected = false;
        });
      }
    },
    checkAllitemsrightArray() {
      if (this.allRightCheckbox) {
        this.itemsrightArray.forEach((element) => {
          element.isSelected = true;
        });
      } else {
        this.itemsrightArray.forEach((element) => {
          element.isSelected = false;
        });
      }
      this.newRightArray = this.itemsrightArray;
    },
    appendRightArray() {
      this.itemsleftArray.forEach((element) => {
        if (element.isSelected == true) {
          let a = {
            ...element,
          };
          a.isSelected = false;
          console.log("aaaaaaaaaa", a);
          this.itemsrightArray.push(a);
        }
      });
      this.itemsleftArray = this.itemsleftArray.filter(
        (item) => item.isSelected !== true
      );
      this.allLeftCheckbox = false;
      this.newLeftArray = this.itemsleftArray;
    },
    appendLeftArray() {
      this.itemsrightArray.forEach((element) => {
        if (element.isSelected == true) {
          let a = {
            ...element,
          };
          a.isSelected = false;
          this.itemsleftArray.push(a);
        }
        this.itemsrightArray = this.itemsrightArray.filter(
          (item) => item.isSelected !== true
        );
      });
      this.allRightCheckbox = false;
      this.newLeftArray = this.itemsleftArray;
      this.newRightArray = this.itemsrightArray;
    },
    //////////////////////////////
    async getRoute() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await customerAPI.route.getOne(this.$route.query.id);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.routeData = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllCustomer() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await customerAPI.customer.getAll(
          localStorage.getItem("branchId"),
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        // let getVan = responseData.data;
        this.itemsleftArray = responseData.data;
        this.newLeftArray = responseData.data;

        // console.log("getVan left", getVan);
        // console.log("itemsrightArray left", this.itemsrightArray);
        // this.itemsrightArray.forEach((element) => {
        //   this.itemsleftArray = getVan.filter((val) => val.id !== element.id);
        // });

        console.log("this.itemsleftArray", this.itemsleftArray);
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
    goToNew() {
      this.$router.push({
        path: "/CUSTOMER/new",
        query: {},
      });
    },
    goToEdit(id) {
      this.$router.push({
        name: "CUSTOMER-edit",
        query: {
          id: id,
        },
      });
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `ต้องการลบ ${item.name} หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await customerAPI.customer.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
    async statusApprove(isApprove) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
      };
      updateResponse = await customerAPI.customer.update(id, this.itemApprove);
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          Swal.fire({
            icon: "success",
            title: `อนุมัติสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },
    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    textSearch(val) {
      console.log({ val });
    },

    async search() {
      this.loading = true;

      const responseSearch = await customerAPI.customer.search({
        search: this.searchInput,
      });

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
